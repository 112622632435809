import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";

import { TranslateService } from "@ngx-translate/core";

import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

import { AuthService } from "app/services/auth/auth.service";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";

import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, PopupRequest } from "@azure/msal-browser";
import { UserService } from "app/services/user/user.service";
import { Project } from "app/shared/models/project.interface";
import moment from "moment";

@Component({
	selector: "login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
	loginForm: UntypedFormGroup;
	msgErrorLogin: string;

	rememberForm: UntypedFormGroup;
	msgErrorRemember: string;
	msgSuccessRemember: string;

	showRemember: boolean;

	entryPointUrl: string;
	entryPointResources: string;

	returnUrl: string;
	returnUrlFeedbalia: string;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FormBuilder} _formBuilder
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		private afAuth: AngularFireAuth,
		private authSvc: AuthService,
		private userSvc: UserService,
		private router: Router,
		private projectSvc: ProjectService,
		private imageSvc: ImagesService,
		private translateService: TranslateService,
		private route: ActivatedRoute,

		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private msalAuthService: MsalService
	) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};

		this.showRemember = false;
		localStorage.removeItem("tokenUser");
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.msalAuthService.handleRedirectObservable().subscribe();

		this.returnUrlFeedbalia = this.route.snapshot.queryParams["returnUrl"];
		this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/inicio";

		if (!this.projectSvc.projectConfig) {
			this.projectSvc.projectConfigSubject.subscribe(async (config: Project) => {
				if (config.enabled_cas_login) {
					if (this.route.snapshot.queryParams["resultCAS"]) {
						this.saveToken(atob(this.route.snapshot.queryParams["resultCAS"]));
						this.router.navigate(["/inicio"], { replaceUrl: true });
					} else {
						window.location.href = "/saml/login";
					}
				}
			});
		} else if (this.projectSvc.projectConfig.enabled_cas_login) {
			if (this.route.snapshot.queryParams["resultCAS"]) {
				this.saveToken(atob(this.route.snapshot.queryParams["resultCAS"]));
				this.router.navigate(["/inicio"], { replaceUrl: true });
			} else {
				window.location.href = "/saml/login";
			}
		}

		this.loginForm = this._formBuilder.group({
			usuario: ["", [Validators.required]],
			password: ["", Validators.required],
		});
		this.rememberForm = this._formBuilder.group({
			emailRemember: ["", [Validators.required]],
		});
		this.msgErrorLogin = "";
		this.msgErrorRemember = "";
		this.msgSuccessRemember = "";

		this.entryPointUrl = this.projectSvc.getEntryPointUrl();
		this.entryPointResources = this.projectSvc.getEntryPointResources();

		this.afAuth.authState.subscribe((user) => {
			console.log("user firebase", user);
			if (user) {
				this.proccessLoginFirebase(user);
			}
		});
	}

	onLogin(): void {
		this.msgErrorLogin = "";
		this.msgSuccessRemember = "";
		const formValue = this.loginForm.value;

		if (this.loginForm.valid) {
			this.authSvc.login(formValue.usuario, formValue.password).subscribe(
				(data) => {
					if (data) {
						this.projectSvc.getProjectConfig().subscribe((project) => {
							if (project.enabledVerificationCodeLogin) {
								this.router.navigate(["/codigo-seguridad"], {
									replaceUrl: true,
									queryParams: { returnUrl: this.returnUrl },
									state: { accessData: JSON.stringify(data) },
								});
							} else {
								this.saveToken(JSON.stringify(data));
								if (project.id == 472) {
									this.userSvc.getUser().subscribe((data) => {
										if (data["onBoardingOK"] == 0) {
											this.router.navigate(["onboarding"]);
										} else {
											this.router.navigate([this.returnUrlFeedbalia ? this.returnUrlFeedbalia : "/inicio"], { replaceUrl: true });
										}
									});
								} else {
									this.router.navigate([this.returnUrlFeedbalia ? this.returnUrlFeedbalia : "/inicio"], { replaceUrl: true });
								}
							}
						});
					}
				},
				(err) => {
					this.msgErrorLogin = err;
				}
			);
		}
	}

	loginGoogle() {
		this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
	}

	private proccessLoginFirebase(user) {
		this.authSvc.loginSocial(user.email).subscribe(
			(data) => {
				this.afAuth.signOut();
				if (data) {
					this.router.navigate([this.returnUrl]);
				}
			},
			(err) => {
				this.afAuth.signOut();
				this.msgErrorLogin = err;
			}
		);
	}

	onRemember(): void {
		this.msgErrorRemember = "";
		const formValue = this.rememberForm.value;
		// console.log("formValue remember", formValue);

		if (this.rememberForm.valid) {
			this.authSvc.rememberPassword(formValue.emailRemember).subscribe(
				(data) => {
					this.showRemember = false;
					this.msgSuccessRemember = "Se ha enviado un email a tu cuenta para restablecer la contraseña.";
				},
				(err) => {
					this.msgErrorRemember = err;
				}
			);
		}
	}

	loginOutlook() {
		if (this.msalGuardConfig.authRequest) {
			this.msalAuthService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
				this.msalAuthService.instance.setActiveAccount(response.account);
				this.projectSvc.sendTokenToManu(response).subscribe((data) => {
					console.log("Sent Token", response);
				});
				this.proccessLoginOutlook(response.account, response.accessToken);
			});
		} else {
			this.msalAuthService.loginPopup().subscribe((response: AuthenticationResult) => {
				this.msalAuthService.instance.setActiveAccount(response.account);
				this.projectSvc.sendTokenToManu(response).subscribe((data) => {
					console.log("Sent Token", response);
				});
				this.proccessLoginOutlook(response.account, response.accessToken);
			});
		}
	}

	logoutOutlook() {
		this.msalAuthService.logoutPopup({
			postLogoutRedirectUri: "/",
			mainWindowRedirectUri: "/",
		});
	}

	canLoginGoogle() {
		//if (["hospitaloptimista", "bluespace", "iberext", "funespana"].includes(this.projectSvc.getProjectName()) || this.projectSvc.projectConfig?.enabled_cas_login) {
		if (this.projectSvc.projectConfig?.disabledLoginGoogle || this.projectSvc.projectConfig?.enabled_cas_login) {
			return false;
		} else {
			return true;
		}
	}

	canLoginOutlook() {
		//if (["hospitaloptimista", "bluespace"].includes(this.projectSvc.getProjectName()) || this.projectSvc.projectConfig?.enabled_cas_login) {
		if (this.projectSvc.projectConfig?.disabledLoginMicrosoft || this.projectSvc.projectConfig?.enabled_cas_login) {
			return false;
		} else {
			return true;
		}
	}

	canLogin() {
		//if (["hospitaloptimista", "iberext", "funespana"].includes(this.projectSvc.getProjectName()) ||this.projectSvc.projectConfig?.enabled_cas_login) {
		if (this.projectSvc.projectConfig?.disabledLogin || this.projectSvc.projectConfig?.enabled_cas_login) {
			return false;
		} else {
			return true;
		}
	}

	canRememberPassword() {
		if (["iberext", "funespana"].includes(this.projectSvc.getProjectName()) || this.projectSvc.projectConfig?.enabled_cas_login) {
			return false;
		} else {
			return true;
		}
	}

	private proccessLoginOutlook(account, accessToken) {
		this.authSvc.loginSocial(account.username, accessToken).subscribe(
			(res) => {
				// this.logoutOutlook();
				const projectId = this.projectSvc.projectConfig.id;
				if (projectId == 472) {
					this.userSvc.getUser().subscribe((data) => {
						if (data["onBoardingOK"] == 0) {
							this.router.navigate(["onboarding"]);
						} else {
							this.router.navigate([this.returnUrl]);
						}
					});
				} else {
					this.router.navigate([this.returnUrl]);
				}
			},
			(err) => {
				// this.logoutOutlook();
				this.msgErrorLogin = err;
			}
		);
	}

	private saveToken(token): void {
		token = JSON.parse(token);

		let now = moment();
		let dateExpired = now.toDate().getTime() + token.expires_in;
		token.dateExpired = dateExpired;

		const tokenString = JSON.stringify(token);
		localStorage.setItem("tokenUser", tokenString);
	}
}
